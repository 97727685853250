import {
  ArrowRightIcon,
  FileSpreadsheetIcon,
  TablePropertiesIcon,
  RefreshCwIcon,
  BracesIcon,
  TableIcon,
  ZapIcon,
  LockIcon,
  RefreshCcwIcon,
  GlobeIcon,
  SparklesIcon,
  LinkIcon,
  LayersIcon,
  DatabaseIcon,
  CheckIcon,
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  AlignLeftIcon,
  AlignCenterIcon,
  AlignRightIcon,
  TypeIcon,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { render } from "react-dom";
interface SelectedCell {
  row: number;
  col: number;
}
interface TableRow {
  [key: string]: string;
}
export default function SheetsApp(): JSX.Element {
  const [tableData, setTableData] = useState<string[][]>([
    ["Name", "Age", "City"],
    ["John Doe", "30", "New York"],
    ["Jane Smith", "25", "London"],
    ["Bob Johnson", "35", "Paris"],
  ]);
  const [jsonPreview, setJsonPreview] = useState<string>("");
  const [isConverting, setIsConverting] = useState<boolean>(false);
  const [selectedCell, setSelectedCell] = useState<SelectedCell | null>(null);
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    document.documentElement.style.scrollBehavior = "smooth";
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleCellChange = (
      rowIndex: number,
      colIndex: number,
      value: string,
  ): void => {
    const newData = tableData.map((row, i) =>
        i === rowIndex
            ? row.map((cell, j) => (j === colIndex ? value : cell))
            : row,
    );
    setTableData(newData);
  };
  const getColumnLabel = (index: number): string => {
    let label = "";
    while (index >= 0) {
      label = String.fromCharCode(65 + (index % 26)) + label;
      index = Math.floor(index / 26) - 1;
    }
    return label;
  };
  useEffect(() => {
    setIsConverting(true);
    const timer = setTimeout(() => {
      const jsonData: TableRow[] = tableData.slice(1).map((row) => {
        return row.reduce<TableRow>((obj, cell, index) => {
          obj[tableData[0][index]] = cell;
          return obj;
        }, {});
      });
      setJsonPreview(JSON.stringify(jsonData, null, 2));
      setIsConverting(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [tableData]);
  return (
      <div className="w-full min-h-screen bg-white">
        <nav
            className={`w-full fixed top-0 z-50 transition-all duration-300 ${scrolled ? "bg-white/80 backdrop-blur-md shadow-md" : "bg-white"}`}
        >
          <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-12 py-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-8">
                <FileSpreadsheetIcon className="h-8 w-8 text-green-600" />
                <div className="hidden md:flex items-center space-x-6">
                  <a
                      href="#features"
                      className="text-gray-600 hover:text-gray-900 transition-colors"
                  >
                    Features
                  </a>
                  <a
                      href="#pricing"
                      className="text-gray-600 hover:text-gray-900 transition-colors"
                  >
                    Pricing
                  </a>
                  <a
                      href="#docs"
                      className="text-gray-600 hover:text-gray-900 transition-colors"
                  >
                    Documentation
                  </a>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <button className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors flex items-center">
                  Try Demo <ArrowRightIcon className="ml-2 h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        </nav>

        <main className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-12 py-12 xl:py-20 mt-16">
          {/* Rest of the component remains exactly the same */}
          <div className="text-center max-w-[1200px] mx-auto">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-900 mb-6">
              Transform your <span className="text-green-700">Google Sheets</span> to JSON API with syncSheets.
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Create, edit, and convert spreadsheet data to JSON instantly
            </p>
            <div className="flex items-center justify-center space-x-4">
              <button className="px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 flex items-center">
                Try Demo <ArrowRightIcon className="ml-2 h-5 w-5"/>
              </button>
              <button className="px-6 py-3 border border-gray-300 rounded-lg hover:border-gray-400">
                View Documentation
              </button>
            </div>
          </div>

          <div className="mt-16 max-w-[1200px] mx-auto bg-white rounded-xl shadow-lg p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-xl font-bold mb-4">Interactive Demo</h3>
                <div className="flex items-center mb-2">
                  <div className="bg-gray-100 px-4 py-2 rounded-t-lg border border-gray-300 text-sm font-medium text-gray-700">
                    Sheet1
                  </div>
                </div>
                <div className="flex items-center space-x-2 mb-2 p-2 border border-gray-200 rounded-t bg-gray-50">
                  <button className="p-1.5 hover:bg-gray-200 rounded">
                    <BoldIcon className="h-4 w-4" />
                  </button>
                  <button className="p-1.5 hover:bg-gray-200 rounded">
                    <ItalicIcon className="h-4 w-4" />
                  </button>
                  <button className="p-1.5 hover:bg-gray-200 rounded">
                    <UnderlineIcon className="h-4 w-4" />
                  </button>
                  <div className="h-4 w-px bg-gray-300 mx-1" />
                  <button className="p-1.5 hover:bg-gray-200 rounded">
                    <AlignLeftIcon className="h-4 w-4" />
                  </button>
                  <button className="p-1.5 hover:bg-gray-200 rounded">
                    <AlignCenterIcon className="h-4 w-4" />
                  </button>
                  <button className="p-1.5 hover:bg-gray-200 rounded">
                    <AlignRightIcon className="h-4 w-4" />
                  </button>
                  <div className="h-4 w-px bg-gray-300 mx-1" />
                  <select className="text-sm border border-gray-300 rounded px-2 py-1">
                    <option>Arial</option>
                    <option>Helvetica</option>
                    <option>Times New Roman</option>
                  </select>
                  <select className="text-sm border border-gray-300 rounded px-2 py-1">
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>14</option>
                  </select>
                </div>
                <div className="overflow-x-auto border border-gray-200">
                  <table className="min-w-full border-collapse">
                    <thead>
                    <tr>
                      <th className="w-10 bg-gray-50 border-b border-r border-gray-200"></th>
                      {tableData[0].map((_, index) => (
                          <th
                              key={index}
                              className="bg-gray-50 border-b border-r border-gray-200 px-4 py-2 text-sm font-medium text-gray-600"
                          >
                            {getColumnLabel(index)}
                          </th>
                      ))}
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="bg-gray-50 border-r border-b border-gray-200 px-2 py-1 text-sm text-gray-600 text-center">
                            {rowIndex + 1}
                          </td>
                          {row.map((cell, colIndex) => (
                              <td
                                  key={colIndex}
                                  className={`border-r border-b border-gray-200 p-0 relative ${selectedCell?.row === rowIndex && selectedCell?.col === colIndex ? "outline outline-2 outline-green-500 z-10" : ""}`}
                              >
                                <input
                                    type="text"
                                    value={cell}
                                    onChange={(e) =>
                                        handleCellChange(
                                            rowIndex,
                                            colIndex,
                                            e.target.value,
                                        )
                                    }
                                    onFocus={() =>
                                        setSelectedCell({
                                          row: rowIndex,
                                          col: colIndex,
                                        })
                                    }
                                    className="w-full h-full px-2 py-1 focus:outline-none hover:bg-gray-50"
                                />
                              </td>
                          ))}
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-xl font-bold">Live JSON Preview</h3>
                  {isConverting && (
                      <div className="flex items-center text-green-600">
                        <RefreshCwIcon className="animate-spin h-5 w-5 mr-2" />
                        Converting...
                      </div>
                  )}
                </div>
                <pre className="bg-gray-50 p-4 rounded-lg overflow-auto max-h-[300px]">
                <code>{jsonPreview}</code>
              </pre>
              </div>
            </div>
          </div>

          <div id="features" className="mt-24">
            <h2 className="text-3xl font-bold text-center mb-12">Key Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="p-6 rounded-lg border border-gray-200">
                <TableIcon className="h-8 w-8 text-green-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Interactive Tables</h3>
                <p className="text-gray-600">
                  Edit and manage your data with an intuitive spreadsheet
                  interface.
                </p>
              </div>
              <div className="p-6 rounded-lg border border-gray-200">
                <BracesIcon className="h-8 w-8 text-green-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">JSON Conversion</h3>
                <p className="text-gray-600">
                  Instantly convert your spreadsheet data to JSON format.
                </p>
              </div>
              <div className="p-6 rounded-lg border border-gray-200">
                <ZapIcon className="h-8 w-8 text-green-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Real-time Updates</h3>
                <p className="text-gray-600">
                  See changes reflected immediately in the JSON preview.
                </p>
              </div>
            </div>
          </div>

          <div id="pricing" className="mt-24">
            <h2 className="text-3xl font-bold text-center mb-12">
              Pricing Plans
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="p-8 rounded-lg border border-gray-200">
                <h3 className="text-2xl font-bold mb-4">Free</h3>
                <p className="text-4xl font-bold mb-6">
                  $0<span className="text-lg text-gray-600">/month</span>
                </p>
                <ul className="space-y-4">
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-500 mr-2"/>
                    Basic features
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-500 mr-2"/>1
                    workspace
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-500 mr-2"/>
                    100 API requests a month.
                  </li>
                </ul>
              </div>
              <div className="p-8 rounded-lg border border-green-500 bg-green-50">
                <h3 className="text-2xl font-bold mb-4">Pro</h3>
                <p className="text-4xl font-bold mb-6">
                  $19<span className="text-lg text-gray-600">/month</span>
                </p>
                <ul className="space-y-4">
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-500 mr-2"/>
                    All Free features
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-500 mr-2"/>
                    5 workspaces
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-500 mr-2"/>
                    100 API requests a month.
                  </li>
                </ul>
              </div>
              <div className="p-8 rounded-lg border border-gray-200">
                <h3 className="text-2xl font-bold mb-4">Enterprise</h3>
                <p className="text-4xl font-bold mb-6">Custom</p>
                <ul className="space-y-4">
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-500 mr-2" />
                    All Pro features
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-500 mr-2" />
                    Custom solutions
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </main>

        <footer className="bg-gray-50 border-t mt-24">
          <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-12 py-12">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              <div>
                <FileSpreadsheetIcon className="h-8 w-8 text-green-600 mb-4" />
                <p className="text-gray-600">
                  Transform your GSheets to JSON on Live API with SheetsApp.
                </p>
              </div>
              <div>
                <h4 className="font-semibold mb-4">Product</h4>
                <ul className="space-y-2">
                  <li>
                    <a
                        href="#features"
                        className="text-gray-600 hover:text-gray-900"
                    >
                      Features
                    </a>
                  </li>
                  <li>
                    <a
                        href="#pricing"
                        className="text-gray-600 hover:text-gray-900"
                    >
                      Pricing
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h4 className="font-semibold mb-4">Company</h4>
                <ul className="space-y-2">
                  <li>
                    <a
                        href="#about"
                        className="text-gray-600 hover:text-gray-900"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                        href="#contact"
                        className="text-gray-600 hover:text-gray-900"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h4 className="font-semibold mb-4">Legal</h4>
                <ul className="space-y-2">
                  <li>
                    <a
                        href="#privacy"
                        className="text-gray-600 hover:text-gray-900"
                    >
                      Privacy
                    </a>
                  </li>
                  <li>
                    <a
                        href="#terms"
                        className="text-gray-600 hover:text-gray-900"
                    >
                      Terms
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-8 pt-8 border-t text-center text-gray-600">
              © 2024 SheetsApp. All rights reserved.
            </div>
          </div>
        </footer>
      </div>
  );
}
render(<SheetsApp />, document.getElementById("root"));
